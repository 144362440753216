import React from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link, navigate } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import DisplayBlockedTimeBlock from "~/components/Timeblocks/DisplayBlockedTimeBlock";
import DisplayTimeBlock from "~/components/Timeblocks/DisplayTimeBlock";
import type { Nurse } from "~/utils/interfaces/Nurse";
import type { GroupedBlockedTimeBlocks, GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";

interface NurseTimeblocksProps {
  loading: boolean;
  error: any;
  nurseData: Nurse | null;
  activeTimeblocks: GroupedTimeBlocks;
  blockedTimeblocks: GroupedBlockedTimeBlocks;
}

const NurseTimeblocks = ({ loading, error, nurseData, activeTimeblocks, blockedTimeblocks }: NurseTimeblocksProps) => {
  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && nurseData && (
        <>
          <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center mx-4 mt-4">
                <p className="font-bold">Horarios habilitados</p>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={async () => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Link
                    to={`/health-team/${nurseData.id}/schedule/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary">Editar</Button>
                  </Link>
                </ButtonGroup>
              </div>
              <hr />
              <DisplayTimeBlock timeblocks={activeTimeblocks} />
            </div>
          </div>
          <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center mx-4 mt-4">
                <p className="font-bold">Horarios bloqueados</p>
                <Link
                  to={`/health-team/${nurseData.id}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </div>
              <hr />
              <DisplayBlockedTimeBlock timeblocks={blockedTimeblocks} />
            </div>
          </div>
        </>
      )}
    </PrivateRoute>
  );
};

export default NurseTimeblocks;
